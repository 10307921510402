import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
         <strong className="purple">PROJECTS</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="DjangoTodoList"
              description="Personal to do list app buil with Django, react.js, Material-UI, and Postgres. CRUD operations implemented. Deployed to Heroku"
              ghLink="https://github.com/trudeauokech"
              demoLink="https://todo-list-trudeau-3e2b9e0a2dd6.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Text to HTML Converter"
              description="A simple text to HTML converter built using Django and deployed to Heroku"
              ghLink="https://github.com/trudeauokech"
              demoLink="https://text-to-html-3ec7178af173.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="WeatherApp"
              description="Weather App using OpenWeather API & Rapid API. Hosted on Netlify"
              ghLink="https://github.com/TrudeauOkech/weather"
              demoLink="https://cerulean-boba-e37ed6.netlify.app/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="PremAI AI"
              description="This is a project to build a predictor for premier league matches using Random Forest

."
              ghLink="https://github.com/TrudeauOkech/Premier-League-Prediction-Model/blob/main/PremierLeaguePredictionusingRandomForest.ipynb"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="TitanicPrediction"
              description="Building a ML(Classification) model to predict likelihood of survival of Titanic passengers. Inspired by famous kaggle competition"
              ghLink="https://github.com/TrudeauOkech/TitanicPrediction"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="This is a nodejs CRUD app to add, delete and update books in a bookshop/library"
              ghLink="https://github.com/TrudeauOkech/LibraryBook"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
